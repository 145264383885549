import {show} from './modal';

import {fetchBaseModalData} from './utils';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


export const showAbsenceModal = (lang, type) => {
	let checkedIds = [];

	document.querySelectorAll(".list-check").forEach(check => {
		if (check.checked) {
			checkedIds.push(check.value);
		}
	})

	if (type == "auto") {
		fetchBaseModalData(lang, null, langTxt => {
			let div = document.createElement("div");
			div.innerHTML = langTxt.gen["Set As : Automatic Assignation"];

			show(langTxt.gen["Confirm"], div, () => {
				let formData = new FormData();
				formData.append("ids", JSON.stringify(checkedIds));
				formData.append("needs_substitute", "1");

				fetch("/" + lang + "/admin/long-absences/batch-update", {
					method : "POST",
					body : formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token,
					})
				})
				.then(res => res.json())
				.then(res => {
					window.location.reload();
				})
			});
		});
	}
	else if (type == "none") {
		fetchBaseModalData(lang, null, langTxt => {
			let div = document.createElement("div");
			div.innerHTML = langTxt.gen["Set As : No Substitute Needed"];

			show(langTxt.gen["Confirm"], div, () => {
				let formData = new FormData();
				formData.append("ids", JSON.stringify(checkedIds));
				formData.append("needs_substitute", "0");

				fetch("/" + lang + "/admin/long-absences/batch-update", {
					method : "POST",
					body : formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token,
					})
				})
				.then(res => res.json())
				.then(res => {
					window.location.reload();
				})
			});
		});
	}
	else if (type == "planifText") {
		fetchBaseModalData(lang, null, langTxt => {
			let div = document.createElement("div");

			let textarea = document.createElement("textarea");
			textarea.classList.add("form-control");
			textarea.style.height = "150px";

			div.append(textarea);

			show(langTxt.gen["Confirm"], div, () => {
				let formData = new FormData();
				formData.append("ids", JSON.stringify(checkedIds));
				formData.append("substitute_text", textarea.value);

				fetch("/" + lang + "/admin/long-absences/batch-update", {
					method : "POST",
					body : formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token,
					})
				})
				.then(res => res.json())
				.then(res => {
					window.location.reload();
				})
			});
		});
	}
	else if (type == "choose") {
		fetchBaseModalData(lang, "teachers", (langTxt, teachers) => {
			let div = document.createElement("div");

			let select = document.createElement("select");
			select.classList.add("form-select", "teacher-id-select");

			div.append(select);

			teachers.forEach(teacher => {
				let option = document.createElement("option");
				option.value = teacher.id
				option.innerText = teacher.last_name + ", " + teacher.first_name;
				select.append(option);
			})

			show(langTxt.gen["Confirm"], div, () => {
				let formData = new FormData();
				formData.append("ids", JSON.stringify(checkedIds));
				formData.append("needs_substitute", "1");
				formData.append("substitute_id", select.value);

				fetch("/" + lang + "/admin/long-absences/batch-update", {
					method : "POST",
					body : formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token,
					})
				})
				.then(res => res.json())
				.then(res => {
					window.location.reload();
				})
			});
		});
	}
	else if (type == "substitutionType") {
		fetchBaseModalData(lang, null, (langTxt, teachers) => {
			let div = document.createElement("div");

			let select = document.createElement("select");
			select.classList.add("form-select", "substitution-type-select");

			div.append(select);

			let option = document.createElement("option");
			option.value = "surveillance";
			option.innerText = langTxt.gen["Surveillance"];
			select.append(option);

			option = document.createElement("option");
			option.value = "substitution";
			option.innerText = langTxt.gen["Substitution"];
			select.append(option);

			option = document.createElement("option");
			option.value = "substitution_occasional";
			option.innerText = langTxt.gen["Substitution Occasional"];
			select.append(option);

			show(langTxt.gen["Confirm"], div, () => {
				let formData = new FormData();
				formData.append("ids", JSON.stringify(checkedIds));
				formData.append("substitution_type", select.value);

				fetch("/" + lang + "/admin/long-absences/batch-update", {
					method : "POST",
					body : formData,
					headers: new Headers({
						"X-CSRF-TOKEN": token,
					})
				})
				.then(res => res.json())
				.then(res => {
					window.location.reload();
				})
			});
		});
	}
}