import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import ModalComp from './modal-comp';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId) => {
	ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <Prediction lang={lang} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
};

const getFirstDayOfWeek = date => {
	date = new Date(date);
	return new Date(date.setDate(date.getDate() - date.getDay() + 1));
}

function Prediction (props) {
	const [lang, updateLang] = useState(null);
	const [week, updateWeek] = useState(getFirstDayOfWeek(new Date().toLocaleDateString("fr-CA")).toLocaleDateString("fr-CA"));
	const [weekInfo, updateWeekInfo] = useState(null);
	const [weekPredictions, updateWeekPredictions] = useState([0, 0, 0, 0, 0]);
	const [viewType, setDetailedType] = useState(localStorage.getItem("prediction-view-type") ?? "summary");
	const [columnViewType, setColumnViewType] = useState(localStorage.getItem("prediction-column-view-type") ?? "all");
	const [viewRange, setViewRange] = useState(localStorage.getItem("prediction-view-range") ?? "2w");
	const [isLoading, setLoading] = useState(true);
	const [plan, setPlan] = useState(null);
	const [justSaved, setJustSaved] = useState(false);
	const justSavedId = useRef(null);

	useEffect(() => {
		if (justSaved) {
			if (justSavedId.current != null) {
				window.clearTimeout(justSavedId.current);
			}

			justSavedId.current = window.setTimeout(() => {
				justSavedId.current = null;
				setJustSaved(false)
			}, 2000);
		}

		return () => {
			if (justSavedId.current != null) {
				window.clearTimeout(justSavedId.current);
				justSavedId.current = null
			}
		}
	}, [justSaved])

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
		});
	}, [props.lang]);

	useEffect(() => {
		if (weekInfo != null) {
			let preds = [];

			weekInfo.forEach(day => {
				preds.push(day.prediction);
			});

			updateWeekPredictions(preds);
		}
	}, [weekInfo])

	useEffect(() => {
		localStorage.setItem("prediction-view-range", viewRange);

		setLoading(true);

		fetch("/" + props.lang + "/admin/absences/predictions/" + week + "/" + viewRange)
		.then(res => res.json())
		.then(res => {
			setLoading(false);
			updateWeekInfo(res);
		});
	}, [week, viewRange])

	const changeWeek = w => {
		let date = getFirstDayOfWeek(w);
		if (date instanceof Date && !isNaN(date.valueOf())) {
			updateWeek(date.toLocaleDateString("fr-CA"));
		}
	}

	const calculateState = day => {
		return day.periods.reduce((el1, el2) => el1 + el2["total_state"], 0);
	}

	const calculatePredState = day => {
		return day.periods.reduce((el1, el2) => el1 + el2["total_pred_state"], 0);
	}

	const showPeriod = period => {
		return viewType === "detailed" || hasIssue(period);
	}

	const hasIssue = period => {
		return period["total_pred_state"] > 0 || period["total_state"] > 0;
	}

	const changeViewType = type => {
		localStorage.setItem("prediction-view-type", type);
		setDetailedType(type);
	}

	const changeColumnViewType = type => {
		localStorage.setItem("prediction-column-view-type", type);
		setColumnViewType(type);
	}

	const definePlan = (dayIdx, periodIdx) => {
		let text = weekInfo[dayIdx].periods[periodIdx]["plan"] ?? "";
		let planInfo = { dayIdx, periodIdx, text}

		setPlan(planInfo);
	}

	const updatePlan = text => {
		let p = {...plan};
		p.text = text;
		setPlan(p);
	}

	const savePlan = (e) => {
		e.preventDefault();

		let newInfo = [...weekInfo];
		newInfo[plan.dayIdx].periods[plan.periodIdx]["plan"] = plan.text;

		let formData = new FormData();
		formData.append("date", newInfo[plan.dayIdx].date);
		formData.append("period", newInfo[plan.dayIdx].periods[plan.periodIdx]["no"]);
		formData.append("text", plan.text);

		fetch("/" + props.lang + "/admin/absences/predictions/plan", {
			method : "POST",
			body : formData,
			headers: new Headers({
				"X-CSRF-TOKEN": token
			})
		})
		.then(res => res.json())
		.then(res => {
			updateWeekInfo(newInfo);
			setJustSaved(true);
		});

		setPlan(null);
	}

	let shownCount = 0;

	return 	<div>
				{
					justSaved ?
						<div className='position-absolute alert alert-success fade-out p-2' style={{top:60, right : 30, fontSize : 14}}>
							<i className='fa-solid fa-check'></i> {lang.gen["Modification saved"]}
						</div>
					:
						null
				}
				{
					plan != null ?
						<form action="" method="post" onSubmit={savePlan}>
							<ModalComp lang={lang} title={lang.gen["Action plan"]} width={700} confirmIsSubmit={true} closeAction={() => setPlan(null)}>
								<textarea className='form-control' style={{minHeight : 200}} value={plan.text} onChange={e => updatePlan(e.target.value)}></textarea>
							</ModalComp>
						</form>
					:
						null
				}
				{
					lang != null ?
						<div className='row mb-4'>
							<div className='col-sm-2'>
								<input type="date" className='form-control' value={week} onChange={e => changeWeek(e.target.value)} />
							</div>
							<div className='col-sm-2'>
								<select value={viewRange} className='form-select' onChange={(e) => setViewRange(e.target.value)}>
									<option value="1w">1 {lang.gen["week"]}</option>
									<option value="2w">2 {lang.gen["weeks"]}</option>
									<option value="1q">1 {lang.gen["quarter"]}</option>
								</select>
							</div>
							<div className='col-sm-4 d-flex justify-content-center'>
								<div className="btn-group" role="group">
									<button type="button" onClick={() => changeColumnViewType("main")} className={"btn btn-secondary " + (columnViewType === "action-plan" ? "active" : "")}>{lang.gen["Main columns"]}</button>
									<button type="button" onClick={() => changeColumnViewType("all")} className={"btn btn-secondary " + (columnViewType === "all" ? "active" : "")}>{lang.gen["All columns"]}</button>
								</div>
							</div>
							<div className='col-sm-4 d-flex justify-content-end'>
								<div className="btn-group" role="group">
									<button type="button" onClick={() => changeViewType("summary")} className={"btn btn-info " + (viewType === "summary" ? "active" : "")}>{lang.gen["Summary view"]}</button>
									<button type="button" onClick={() => changeViewType("issue")} className={"btn btn-info " + (viewType === "issue" ? "active" : "")}>{lang.gen["Issue view"]}</button>
									<button type="button" onClick={() => changeViewType("detailed")} className={"btn btn-info " + (viewType === "detailed" ? "active" : "")}>{lang.gen["Detailed view"]}</button>
								</div>
							</div>
						</div>
					:
						null
				}
				{
					weekInfo != null ?
						<div className={isLoading ? "opacity-25" : ""}>
							<table className='table text-xs'>
								<tbody>
									{
										weekInfo.map((day, dayIdx) => {
											let dayTitle = day["dayName"] + " " + day["date"] + " (" + lang.gen["day"] + " " + (day["schoolDay"] < 0 ? "--" : day["schoolDay"]) + ")";

											if (viewType !== "issue" || day.periods.filter(p => hasIssue(p)).length > 0) {
												shownCount++;
											}

											return viewType !== "issue" || day.periods.filter(p => hasIssue(p)).length > 0 ?
													<tr key={"day-" + dayIdx} style={day.dayOfWeek == 5 && viewType !== "issue" ? {borderBottom : "2px solid black"} : {}}>
														<td>
															<h6 style={{height:35}} className='text-sm'>
																{
																	viewType != "summary" ? dayTitle : ""
																}
															</h6>
															{
																viewType === "detailed" || viewType === "issue" ?
																	day.periods.map((period, periodIdx) => {
																		return showPeriod(period) ?
																					<div key={"period-" + periodIdx}>
																						{lang.gen["Period"]} {period["no"]}
																					</div>
																				:
																					null
																	})
																:
																	null
															}
															<div className='fw-bold'>
																{viewType != "summary" ? "Total" : dayTitle}
															</div>
														</td>
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Declared absences"] : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["absences"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["absences"], 0)}</div>
																</td>
															:
																null
														}
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["School activities"] : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["school_activities"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["school_activities"], 0)}</div>
																</td>
															:
																null
														}
														<td style={{ backgroundColor : "#ddd" }}>
															<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Replacements needed"] : ""}</h6>
															{
																viewType === "detailed" || viewType === "issue" ?
																	day.periods.map((period, periodIdx) => {
																		return showPeriod(period) ?
																					<div key={"period-" + periodIdx} className='text-center' title={ period["absences"] + " + " + period["school_activities"] }>
																						{period["total_need"]}
																					</div>
																				:
																					null
																	})
																:
																	null
															}
															<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["total_need"], 0)}</div>
														</td>
														{
															columnViewType === "all" ?
																<td className='bg-light-green'>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Replacement prediction"] : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center' title={period["prediction_ratio"] + "%"}>
																								{period["prediction"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>
																		{weekPredictions[dayIdx] ?? ""}
																	</div>
																</td>
															:
																null
														}
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Free Teachers"] : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["free_teachers"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["free_teachers"], 0)}</div>
																</td>
															:
																null
														}
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? "28-29" : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["28_29s"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["28_29s"], 0)}</div>
																</td>
															:
																null
														}
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["study-room"] : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["study_rooms"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["study_rooms"], 0)}</div>
																</td>
															:
																null
														}
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? (lang.gen["Volunteers"] + " (24h)") : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["volunteers_24"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["volunteers_24"], 0)}</div>
																</td>
															:
																null
														}
														{
															columnViewType === "all" ?
																<td>
																	<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Volunteers"] + " (" + lang.gen["always"] + ")" : ""}</h6>
																	{
																		viewType === "detailed" || viewType === "issue" ?
																			day.periods.map((period, periodIdx) => {
																				return showPeriod(period) ?
																							<div key={"period-" + periodIdx} className='text-center'>
																								{period["volunteers_always"]}
																							</div>
																						:
																							null
																			})
																		:
																			null
																	}
																	<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["volunteers_always"], 0)}</div>
																</td>
															:
																null
														}
														<td style={{ backgroundColor : "#ddd" }}>
															<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Replacements offer"] : ""}</h6>
															{
																viewType === "detailed" || viewType === "issue" ?
																	day.periods.map((period, periodIdx) => {
																		return showPeriod(period) ?
																					<div key={"period-" + periodIdx} className='text-center' title={ period["free_teachers"] + " + " + period["28_29s"] + " + " + period["study_rooms"] + " + " + period["volunteers_24"] + " + " + period["volunteers_always"] }>
																						{period["total_avail"]}
																					</div>
																				:
																					null
																	})
																:
																	null
															}
															<div className='fw-bold text-center'>{day.periods.reduce((el1, el2) => el1 + el2["total_avail"], 0)}</div>
														</td>
														<td>
															<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Total state"] : ""}</h6>
															{
																viewType === "detailed" || viewType === "issue" ?
																	day.periods.map((period, periodIdx) => {
																		let color = "";

																		if (period["total_state"] < 0) {
																			color = "text-primary";
																		}
																		else if (period["total_state"] > 0) {
																			color = "text-danger";
																		}

																		return showPeriod(period) ?
																					<div key={"period-" + periodIdx} className={'text-center ' + color}>
																						{-period["total_state"]}
																					</div>
																				:
																					null
																	})
																:
																	null
															}
															<div className={'fw-bold text-center ' + (calculateState(day) < 0 ? "text-primary" : "text-danger")}>
																{-calculateState(day)}
																{
																	calculatePredState(day) < 0 && day.periods.filter(p => p["total_state"] > 0).length > 0 ?
																		<span className='text-warning'>
																			<i className='fa-solid fa-triangle-exclamation ms-2'></i>
																			{day.periods.filter(p => p["total_state"] > 0).length}
																		</span>
																	:
																		null
																}
															</div>
														</td>
														<td>
															<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Total prediction state"] : ""}</h6>
															{
																viewType === "detailed" || viewType === "issue" ?
																	day.periods.map((period, periodIdx) => {
																		let color = "";

																		if (period["total_pred_state"] < 0) {
																			color = "text-primary";
																		}
																		else if (period["total_pred_state"] > 0) {
																			color = "text-danger";
																		}

																		return showPeriod(period) ?
																					<div key={"period-" + periodIdx} className={'text-center ' + color}>
																						{-period["total_pred_state"]}
																					</div>
																				:
																					null
																	})
																:
																	null
															}
															<div className={'fw-bold text-center ' + (calculatePredState(day) < 0 ? "text-primary" : "text-danger")}>
																{-calculatePredState(day)}
																{
																	calculatePredState(day) < 0 && day.periods.filter(p => p["total_pred_state"] > 0).length > 0 ?
																		<span className='text-warning'>
																			<i className='fa-solid fa-triangle-exclamation ms-2'></i>
																			{day.periods.filter(p => p["total_pred_state"] > 0).length}
																		</span>
																	:
																		null
																}
															</div>
														</td>
														<td>
															<h6 style={{height:35}} className='text-center text-sm'>{shownCount == 1 ? lang.gen["Action plan"] : ""}</h6>
															{
																viewType === "detailed" || viewType === "issue" ?
																	day.periods.map((period, periodIdx) => {
																		let buttonColor = "text-secondary";

																		if (period.plan != null && period.plan.trim().length > 0) {
																			buttonColor = "text-warning";
																		}

																		return showPeriod(period) ?
																					<div key={"period-" + periodIdx} className='text-center'>
																						<i onClick={() => definePlan(dayIdx, periodIdx)} className={'fa-solid fa-file-lines cursor-pointer fade-hover ' + buttonColor} style={{fontSize : 10}}></i>
																					</div>
																				:
																					null
																	})
																:
																	null
															}
															<div className='fw-bold text-center'>

															</div>
														</td>
													</tr>
												:
													null
										})
									}
								</tbody>
							</table>
							<div className='text-xs'>
								{lang.gen["Prediction focus note"]}
							</div>
						</div>
					:
					<div className='mt-4 d-flex justify-content-center'>
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					</div>
				}
			</div>
}6