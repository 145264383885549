import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import ModalComp from './modal-comp';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export const load = (lang, nodeId) => {
	ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
                <PredictionsGen lang={lang} />
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById(nodeId)
    )
};

function PredictionsGen (props) {
	const [lang, updateLang] = useState(null);
	const [dates, updateDates] = useState(null);
	const [justSaved, setJustSaved] = useState(false);
	const justSavedId = useRef(null);
	const [showSchoolActivities, setShowSchoolActivities] = useState(true);

	useEffect(() => {
		if (justSaved) {
			if (justSavedId.current != null) {
				window.clearTimeout(justSavedId.current);
			}

			justSavedId.current = window.setTimeout(() => {
				justSavedId.current = null;
				setJustSaved(false)
			}, 2000);
		}

		return () => {
			if (justSavedId.current != null) {
				window.clearTimeout(justSavedId.current);
				justSavedId.current = null
			}
		}
	}, [justSaved])

	useEffect(() => {
		fetch("/js/" + props.lang + "/translation.js")
		.then(res => res.json())
		.then(res => {
			updateLang(res);
		});

		fetch("/" + props.lang + "/admin/absences/predictions/generation/fetch")
		.then(res => res.json())
		.then(res => {
			updateDates(res);
		});

	}, [props.lang]);

	const changePrediction = (date, e) => {
		let dateList = [...dates];

		dateList.forEach(d => {
			if (d.date == date) {
				d.prediction = e.target.value;
			}
		});

		updateDates(dateList);
	}

	const savePredictions = () => {
		let dateList = [...dates];
		let data = [];

		dateList.forEach(d => {
			data.push({
				date: d.date,
				prediction: d.prediction ?? d.calculated_prediction ?? 0
			});
		});

		fetch("/" + props.lang + "/admin/absences/predictions/generation/save", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-TOKEN": token
			},
			body: JSON.stringify(data)
		})
		.then(res => res.json())
		.then(res => {
			setJustSaved(true);
		});
	}

	let currentWeek = 0;

	return 	<div>
				{
					lang != null && dates != null ?
						<>
							{
								justSaved ?
									<div className='position-fixed alert alert-success fade-out p-2' style={{top:60, right : 30, fontSize : 14}}>
										<i className='fa-solid fa-check'></i> {lang.gen["Modification saved"]}
									</div>
								:
									null
							}
							{
								dates.length > 0 ?
									<div>
										<div className='d-flex justify-content-end gap-1' style={{bottom:5, right:5, zIndex: 1}}>
											<button type="button" className="btn btn-secondary btn-sm" onClick={() => setShowSchoolActivities(!showSchoolActivities)}>
												{showSchoolActivities ? lang.gen["Hide school activities"] : lang.gen["Show school activities"]}
											</button>
											<button type="button" className="btn btn-primary btn-sm" onClick={() => savePredictions()}>{lang.gen["Save predictions"]}</button>
										</div>
										<table className='table table-xs table-no-padding mb-5'>
											<thead>
												<tr className='border-bottom-0 border-white'>
													<th className='text-center'>{lang.gen["Week"]}</th>
													<th>{lang.gen["Day"]}</th>
													<th>{lang.gen["Date"]}</th>
													<th className='border-end'>{lang.gen["Prediction"]}</th>
													{
														dates[0].previous_years.map((year, index) => {
															return <th colSpan={showSchoolActivities ? 2 : 1} className='text-center border-end' key={index}>{year.year}</th>
														})
													}
												</tr>
												<tr className='text-xs'>
													<th colSpan={4} className='border-end'></th>
													{
														dates[0].previous_years.map((year, index) => {
															return <React.Fragment key={index}>
																		<th className='text-center'>{lang.gen["Absences"]}</th>
																		{
																			showSchoolActivities ?
																				<th className='text-center border-end'>{lang.gen["School activities"]}</th>
																			:
																				null
																		}
																	</React.Fragment>
														})
													}
												</tr>
											</thead>
											<tbody>
												{
													dates.map((date, index) => {
														currentWeek = date.week_no;
														let diffWeek = index + 1 < dates.length && dates[index + 1].week_no != currentWeek && currentWeek != 0;

														return 	<tr key={index} className={diffWeek ? "border-dark" : ""}>
																	<td className='text-center'>{date.week_no}</td>
																	<td>{lang.gen[date.day_of_week] ?? "?"}</td>
																	<td>
																		{date.date}
																		{
																			date.day == 0 ?
																				<span className='text-primary fs-bold ms-1'>(ped)</span>
																			:
																				null
																		}
																	</td>
																	<td style={{width:170}} className='border-end'>
																		<input type="number" size="5" className={'text-center'} min="0" step={1} value={date.prediction ?? date.calculated_prediction ?? "0"} onChange={e => changePrediction(date.date, e)} />
																		{
																			date.prediction != null && date.calculated_prediction != date.prediction ?
																				<span className='ms-2 text-info text-sm'>
																					(pred : {date.calculated_prediction})
																				</span>
																			:
																				null
																		}
																	</td>
																	{
																		date.previous_years.map((year, index) => {
																			return <React.Fragment key={index}>
																						<td title={year.is_ped ? lang.gen["Pedagogical Day"] : null} className={"text-center " + (year.is_ped ? "text-primary " : "") + (!year.found ? "opacity-50" : "")} key={index}>
																							{!year.found ? "-" : year.absences}
																						</td>
																						{
																							showSchoolActivities ?
																								<td title={year.is_ped ? lang.gen["Pedagogical Day"] : null} className={"text-center border-end " + (year.is_ped ? "text-primary " : "") + (!year.found ? "opacity-50" : "")}  key={index+1}>
																									{!year.found ? "-" : year.school_activities}
																								</td>
																							:
																								null
																						}
																					</React.Fragment>
																		})
																	}
																</tr>
													})
												}
											</tbody>
										</table>
									</div>
								:
									<div>
										<div className='mt-4 d-flex justify-content-center fst-italic'>
											{lang.gen["No dates for current year found"]}...
										</div>
									</div>
							}
						</>
					:
						<div className='mt-4 d-flex justify-content-center'>
							<div className="spinner-border" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						</div>
				}
			</div>
}6